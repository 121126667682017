<template>
  <router-view ></router-view>
</template>

<script>
export default {
    name: 'MainIndexWithoutLayout'
};
</script>

<style>

html, body, #app{
  height: 100%;
}
</style>
